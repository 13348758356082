<template>
  <div class="fangjia">
    <van-form @submit="onSubmit"
              ref="fangjiaForm">
      <van-field input-align="right"
                 :value="streetText"
                 readonly
                 clickable
                 required
                 name="street"
                 label="所在小区"
                 placeholder="点击选择所在小区"
                 @click="showPickerStreet = true"
                 :rules="[{ required: true, message: '选择所在小区' }]">
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>
      <van-popup v-model="showPickerStreet"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="columnsStreet"
                    @confirm="onstreetConfirm"
                    @cancel="showPickerStreet = false" />
      </van-popup>

      <van-field input-align="right"
                 v-model="formData.villageName"
                 required
                 name="villageName"
                 label="详细地址"
                 placeholder="详细地址"
                 :rules="[{ required: true, message: '请填写详细地址' }]" />

      <!-- <van-field input-align="right"
                 :value="tdytText"
                 readonly
                 clickable
                 required
                 name="tdyt"
                 label="土地类型"
                 placeholder="点击选择土地所有类型"
                 @click="tdytShowPicker = true"
                 :rules="[{ required: true, message: '选择土地所有类型' }]">
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>

      <van-popup v-model="tdytShowPicker"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="tdytColumns"
                    @confirm="tdytConfirm"
                    @cancel="tdytShowPicker = false" />
      </van-popup> -->

      <van-field input-align="right"
                 :value="tdjbText"
                 readonly
                 clickable
                 required
                 name="tdjb"
                 label="土地级别"
                 placeholder="点击选择土地级别"
                 @click="tdjbShowPicker = true"
                 :rules="[{ required: true, message: '选择土地级别' }]">
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>

      <van-popup v-model="tdjbShowPicker"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="tdjbColumns"
                    @confirm="tdjbConfirm"
                    @cancel="tdjbShowPicker = false" />
      </van-popup>

      <van-field input-align="right"
                 :value="fclxText"
                 readonly
                 clickable
                 required
                 name="fclx"
                 label="房产类型"
                 placeholder="点击选择房产类型"
                 @click="fclxShowPicker = true"
                 :rules="[{ required: true, message: '选择房产类型' }]">
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>
      <van-popup v-model="fclxShowPicker"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="fclxColumns"
                    @confirm="fclxConfirm"
                    @cancel="fclxShowPicker = false" />
      </van-popup>
      <div v-if="fclxText == '套房'">
        <van-field input-align="right"
                   :value="sfdtText"
                   readonly
                   clickable
                   required
                   name="sfdt"
                   label="是否为电梯房"
                   placeholder="点击选择是否为电梯房"
                   @click="sfdtShowPicker = true"
                   :rules="[{ required: true, message: '选择是否为电梯房' }]">
          <template #right-icon>
            <van-icon name="arrow" />
          </template>
        </van-field>
        <van-popup v-model="sfdtShowPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="sfdtColumns"
                      @confirm="sfdtConfirm"
                      @cancel="sfdtShowPicker = false" />
        </van-popup>

        <!-- <van-field input-align="right"
                   :value="cheweiText"
                   readonly
                   clickable
                   required
                   name="chewei"
                   label="是否有车位"
                   placeholder="点击选择是否有车位"
                   @click="cheweiShowPicker = true"
                   :rules="[{ required: true, message: '选择是否有车位' }]">
          <template #right-icon>
            <van-icon name="arrow" />
          </template>
        </van-field>
        <van-popup v-model="cheweiShowPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="cheweiColumns"
                      @confirm="cheweiConfirm"
                      @cancel="cheweiShowPicker = false" />
        </van-popup> -->

        <van-field input-align="right"
                   v-model="formData.szlc"
                   type="digit"
                   required
                   name="szlc"
                   label="所在层数"
                   placeholder="所在层数"
                   :rules="[{ required: true, message: '请填写所在层数' },{ validator: szlcValidator, message: '请输入正确楼层' }]" />

        <van-field input-align="right"
                   v-model="formData.zcs"
                   type="digit"
                   required
                   name="zcs"
                   label="总层数"
                   placeholder="总层数"
                   :rules="[{ required: true, message: '请填写总层数' },{ validator:zcsValidator, message: '请输入正确楼层' }]" />
      </div>

      <van-field input-align="right"
                 v-model="formData.jcnf"
                 type="digit"
                 required
                 name="jcnf"
                 label="建成年份"
                 placeholder="建成年份"
                 :rules="[{ required: true, message: '请填写建成年份' },{ validator:jcnfValidator, message: '请输入正确年份' }]" />
      <van-field input-align="right"
                 v-model="formData.jzmj"
                 type="number"
                 required
                 name="jzmj"
                 label="产权证面积"
                 placeholder="产权证面积"
                 :rules="[{ required: true, message: '请填写产权证面积' },{ validator:jzmjValidator, message: '请输入正确产权证面积' }]" />
      <van-field input-align="right"
                 v-model="formData.gtmj"
                 type="number"
                 required
                 name="gtmj"
                 label="公摊面积"
                 placeholder="公摊面积"
                 :rules="[{ required: true, message: '请填写公摊面积' },{ validator:gtmjValidator, message: '请输入正确公摊面积' }]" />
      <van-field input-align="right"
                 v-model="formData.tdmj"
                 type="number"
                 required
                 name="tdmj"
                 label="土地面积"
                 placeholder="土地面积"
                 :rules="[{ required: true, message: '请填写土地面积' },{ validator:tdmjValidator, message: '请输入正确土地面积' }]" />
      <van-field input-align="right"
                 :value="xqfText"
                 readonly
                 clickable
                 required
                 name="xqf"
                 label="是否为学区房"
                 placeholder="点击选择是否为学区房"
                 @click="xqfShowPicker = true"
                 :rules="[{ required: true, message: '选择是否为学区房' }]">
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>
      <van-popup v-model="xqfShowPicker"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="xqfColumns"
                    @confirm="xqfConfirm"
                    @cancel="xqfShowPicker = false" />
      </van-popup>
      <!--            <div style="margin: 16px;">-->
      <!--                <van-checkbox v-model="aggree" shape="square" style="font-size: 12px" icon-size="14">阅读并同意《相关条款》</van-checkbox>-->
      <!--            </div>-->
      <div style="margin: 16px;">
        <van-button round
                    block
                    type="info"
                    native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { getZpList, fangjiazp } from '@/api/public'
import { Toast } from 'vant';

export default {
  name: 'fangjia',
  data () {
    return {
      formData: {
        streetId: '',
        villageName: '',
        tdlx: '',
        tdyt: '',
        fclx: '',
        sfdt: '',
        chewei: '',
        szlc: '',
        zcs: '',
        htc: '',
        yzj: '',
        jcnf: '',
        jzmj: '',
        gtmj: '',
        tdmj: '',
        xqf: '',
        tdjb: ''
      },
      streetText: '',
      showPickerStreet: false,
      aggree: false,
      columnsStreet: [],
      tdjbText: '',
      tdjbShowPicker: false,
      tdjbColumns: [
        { text: '一级地', value: '1', },
        { text: '二级地', value: '2', },
        { text: '三级地', value: '2', },
        { text: '四级地', value: '2', },
        { text: '五级地', value: '2', },
      ],
      tdytText: '',
      tdytShowPicker: false,
      tdytColumns: [
        { text: '住宅用地', value: '1', },
        { text: '商服用地', value: '2', },
        { text: '工业用地', value: '3', },
      ],
      fclxText: '',
      fclxShowPicker: false,
      fclxColumns: [
        { text: '套房', value: '1', },
        { text: '栋房（整栋抵押）', value: '2', },
        { text: '一层店面（主街）', value: '3', },
        { text: '一层店面（内街）', value: '4', },
        { text: '二层店面', value: '5', },
      ],
      sfdtText: '',
      sfdtShowPicker: false,
      sfdtColumns: [
        { text: '是', value: 'true', },
        { text: '否', value: 'false', },
      ],
      cheweiText: '',
      cheweiShowPicker: false,
      cheweiColumns: [
        { text: '是', value: 'true', },
        { text: '否', value: 'false', },
      ],
      htcText: '',
      htcShowPicker: false,
      htcColumns: [
        { text: '是', value: 'true', },
        { text: '否', value: 'false', },
      ],
      yzjText: '',
      yzjShowPicker: false,
      yzjColumns: [
        { text: '是', value: 'true', },
        { text: '否', value: 'false', },
      ],
      xqfText: '',
      xqfShowPicker: false,
      xqfColumns: [
        { text: '是', value: 'true', },
        { text: '否', value: 'false', },
      ],
    };
  },
  created () {
    this.getZpList();
  },
  methods: {
    getZpList () {
      getZpList().then((res) => {
        this.columnsStreet = res.data;
        this.columnsStreet.forEach((town) => {
          town.children = town.street;
          town.text = town.townName;
          town.street.forEach((street) => {
            street.text = street.streetName;
          })
        })
      }).catch(e => {
        console.log(e);
      })
    },
    onSubmit (values) {
      if (this.fclxText != '套房') {
        this.formData.sfdt = '';
        this.formData.chewei = '';
        this.formData.szlc = '';
        this.formData.zcs = '';
      } else {
        if (Number(this.formData.szlc) > Number(this.formData.zcs)) {
          Toast.fail('所在层数不能大于总层数');
          return false;
        }
      }
      if (this.fclxText != '栋房') {
        this.formData.htc = '';
        this.formData.yzj = '';
      }
      fangjiazp(this.formData).then((res) => {
        this.$router.push({
          path: '/result',
          query: res.data
        });
      }).catch(e => {
        console.log(e);
      })
      console.log('submit', values);
    },
    onstreetConfirm (value, index) {
      this.streetText = value[0] + '-' + value[1];
      this.formData.streetId = this.columnsStreet[index[0]].street[index[1]].streetId;
      this.showPickerStreet = false;
    },
    tdjbConfirm (value) {
      debugger;
      this.tdjbText = value.text;
      this.formData.tdjb = value.value;
      this.tdjbShowPicker = false;
    },
    tdytConfirm (value) {
      this.tdytText = value.text;
      this.formData.tdyt = value.value;
      this.tdytShowPicker = false;
    },
    fclxConfirm (value) {
      this.fclxText = value.text;
      this.formData.fclx = value.value;
      this.fclxShowPicker = false;
    },
    sfdtConfirm (value) {
      this.sfdtText = value.text;
      this.formData.sfdt = value.value;
      this.sfdtShowPicker = false;
    },
    cheweiConfirm (value) {
      this.cheweiText = value.text;
      this.formData.chewei = value.value;
      this.cheweiShowPicker = false;
    },
    szlcValidator (value) {
      return Number(value) > 0;
    },
    zcsValidator (value) {
      return Number(value) > 0;
    },
    htcConfirm (value) {
      this.htcText = value.text;
      this.formData.htc = value.value;
      this.htcShowPicker = false;
    },
    yzjConfirm (value) {
      this.yzjText = value.text;
      this.formData.yzj = value.value;
      this.yzjShowPicker = false;
    },
    jcnfValidator (value) {
      let year = new Date().getFullYear();
      return Number(value) > 1950 && Number(value) <= year;
    },
    jzmjValidator (value) {
      return Number(value) > 0;
    },
    gtmjValidator (value) {
      return Number(value) > 0;
    },
    tdmjValidator (value) {
      return Number(value) > 0;
    },
    xqfConfirm (value) {
      this.xqfText = value.text;
      this.formData.xqf = value.value;
      this.xqfShowPicker = false;
    },
  }
}
</script>
<style lang="less">
.fangjia {
  .van-field__error-message {
    text-align: right;
  }
}
</style>
